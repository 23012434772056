<template>
  <div class="container">
    <div class="products">
      <v-row class="">
        <v-col cols="12" class="top-panel">
          <!-- <div class="arrow-btn">
            <img
              src="../assets/img/arrowBack.svg"
              class="img-pluss-btn"
              alt="arrow-back"
            />
          </div>
          <div class="vertical-line-1"></div> -->
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12" class="title-container py-0">
          <h1 class="page-title mt-top">Профиль</h1>
          <!-- <div class="breadcrums-container">
              <router-link class="breadcrums-title" to="/Restaurants"
                ><span class="breadcrums-click">Рестораны / </span></router-link
              >
              <p class="breadcrums-title">CoffeonSaigon</p>
            </div> -->
        </v-col>
      </v-row>
      <v-row class="title-container-tabs">
        <v-col cols="4" class="pl-0">
          <div class="content-container">
            <v-col cols="12" class="px-0 py-0">
              <div class="col-content py-0 pb-3">
                <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                  <p class="col-title">Имя пользователя</p>
                </v-col>
                <v-col cols="8" class="col-paddings-r-l px-0 py-0">
                  <input class="col-description" v-model="userFullName" ref="userFullNameTextarea" :placeholder="isuserFullNameFocused ? '' : textareaPlaceholder
                    " @focus="isuserFullNameFocused = true" @blur="isuserFullNameFocused = false" />
                </v-col>
              </div>
            </v-col>
            <div class="horizontal-line"></div>
            <v-col cols="12" class="px-0 pt-3 pb-0">
              <div class="col-content px-0 py-0 pb-3">
                <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                  <p class="col-title">Логин</p>
                </v-col>
                <v-col cols="8" class="col-paddings-r-l px-0 py-0">
                  <input class="col-description" v-model="userLogin" ref="descriptionTextarea"
                    :placeholder="isuserLoginFocused ? '' : textareaPlaceholder" @focus="isuserLoginFocused = true"
                    @blur="isuserLoginFocused = false" />
                </v-col>
              </div>
            </v-col>
            <div class="horizontal-line horizonmargin-bottom"></div>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12" class="title-container py-0">
          <p @click="togglePasswordChangeBlock" class="password-title">
            Cменить пароль
          </p>
        </v-col>
      </v-row>
      <div v-if="isActive">
        <v-row class="title-container-tabs">
          <v-col cols="4" class="pl-0">
            <div class="content-container">
              <v-col cols="12" class="px-0 pt-3 pb-0">
                <div class="col-content px-0 pb-0 pb-3">
                  <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                    <p class="col-title">Текущий Пароль</p>
                  </v-col>
                  <v-col cols="7" class="col-paddings-r-l px-0 py-0">
                    <input class="col-description" v-model="userPassword" :type="showPassword[0] ? 'text' : 'password'"
                      ref="userPasswordTextarea" :placeholder="isuserPasswordFocused ? '' : textareaPlaceholder
                        " @focus="isuserPasswordFocused = true" @blur="isuserPasswordFocused = false" />
                  </v-col>
                  <v-col cols="1" class="col-paddings-r-l px-0 py-0">
                    <v-icon @click="togglePasswordVisibility(0)">
                      {{ showPassword[0] ? "mdi-eye" : "mdi-eye-off" }}
                    </v-icon>
                  </v-col>
                </div>
              </v-col>
              <div class="horizontal-line"></div>
              <v-col cols="12" class="px-0 pt-3 pb-0">
                <div class="col-content py-0 pb-3">
                  <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                    <p class="col-title">Новый пароль</p>
                  </v-col>
                  <v-col cols="7" class="col-paddings-r-l px-0 py-0">
                    <input class="col-description" v-model="newPassword" ref="productNameTextarea"
                      :type="showPassword[1] ? 'text' : 'password'" :placeholder="isnewPasswordFocused ? '' : textareaPlaceholder
                        " @focus="isnewPasswordFocused = true" @blur="isnewPasswordFocused = false" />
                  </v-col>
                  <v-col cols="1" class="col-paddings-r-l px-0 py-0">
                    <v-icon @click.prevent="togglePasswordVisibility(1)">
                      {{ showPassword[1] ? "mdi-eye" : "mdi-eye-off" }}
                    </v-icon>
                  </v-col>
                </div>
              </v-col>
              <div class="horizontal-line"></div>
              <v-col cols="12" class="px-0 pt-3 pb-3">
                <div class="col-content px-0 py-0">
                  <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                    <p class="col-title">Повторить пароль</p>
                  </v-col>
                  <v-col cols="7" class="col-paddings-r-l px-0 py-0">
                    <input class="col-description" v-model="newPasswordAgain" ref="newPasswordAgainTextarea"
                      :type="showPassword[2] ? 'text' : 'password'" :placeholder="isnewPasswordAgainFocused ? '' : textareaPlaceholder
                        " @focus="isnewPasswordAgainFocused = true" @blur="isnewPasswordAgainFocused = false" />
                  </v-col>
                  <v-col cols="1" class="col-paddings-r-l px-0 py-0">
                    <v-icon @click="togglePasswordVisibility(2)">
                      {{ showPassword[2] ? "mdi-eye" : "mdi-eye-off" }}
                    </v-icon>
                  </v-col>
                </div>
              </v-col>
              <div class="horizontal-line horizonmargin-bottom"></div>
            </div>
            <p v-if="isSubmitted" :class="{ 'success-text': success, 'error-text': !success }">
              {{
                success
                  ? "Пароль успешно изменен"
                  : "Пароли не совпадают или вы ввели неверный действуйющий пароль"
              }}
            </p>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12" class="title-container py-0">
            <div @click="changeUserPassword()" class="save-btn mt-top" text>
              Сохранить
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<style scoped>
.success-text {
  color: green;
  font-family: Inter;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.error-text {
  color: #e11616;
  font-family: Inter;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.save-btn {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  background: var(--03, #bcb648);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
}

.mt-top {
  margin-top: 23px;
  margin-bottom: 0px;
}

.title-container-tabs {
  padding-left: 38px;
  padding-right: 30px;
  padding-top: 0px;
}

.password-title {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
}

.horizonmargin-bottom {
  margin-bottom: 39px;
}

.v-col.cols-7 .border-hover {
  border-radius: 8px;
  border: 1px solid var(--03, #c1c1c1) !important;
  background: var(--white, #fff);
}

.full-text-input-7 {
  width: 50%;
  /* Растягиваем на всю ширину контейнера */
  white-space: normal;
  /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;
  margin-left: 43px;
  /* min-height: 38px; Минимальная высота для начала */
}

.full-text-input-6 {
  width: 50%;
  /* Растягиваем на всю ширину контейнера */
  white-space: normal;
  /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;
  margin-left: 148px;
  /* min-height: 38px; Минимальная высота для начала */
}

.full-text-input-5 {
  width: 50%;
  /* Растягиваем на всю ширину контейнера */
  white-space: normal;
  /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;
  margin-left: 151px;
  /* min-height: 38px; Минимальная высота для начала */
}

.full-text-input-4 {
  width: 50%;
  /* Растягиваем на всю ширину контейнера */
  white-space: normal;
  /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;
  margin-left: 151px;
  /* min-height: 38px; Минимальная высота для начала */
}

.full-text-input-3 {
  width: 50%;
  /* Растягиваем на всю ширину контейнера */
  white-space: normal;
  /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;
  margin-left: 151px;
  /* min-height: 38px; Минимальная высота для начала */
}

.full-text-input-2 {
  width: 50%;
  /* Растягиваем на всю ширину контейнера */
  white-space: normal;
  /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;
  margin-left: 38px;
  /* min-height: 38px; Минимальная высота для начала */
}

.full-text-input {
  width: 50%;
  /* Растягиваем на всю ширину контейнера */
  white-space: normal;
  /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;
  margin-left: 30px;
  /* min-height: 38px; Минимальная высота для начала */
}

.full-text-input,
.full-text-input-2,
.full-text-input-3,
.full-text-input-4,
.full-text-input-5,
.full-text-input-6,
.full-text-input-7,
::placeholder {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.full-text-input,
:focus {
  outline: none;
  /* Убираем внешний контур (outline) при фокусе */
  border: none;
  /* Убираем границу (border) при фокусе */
  box-shadow: none;
  /* Убираем тень (box-shadow) при фокусе */
}

.horizontal-line {
  /* Ширина div'а будет равна 100% от родительского контейнера */
  height: 1px;
  /* Толщина линии */
  background: #848484;
  /* Цвет линии (в данном случае черный) */
  margin-right: 12px;
  /* margin-left: 12px; */
}

.horizontal-line-img {
  height: 1px;
  background: #848484;
  margin-right: 12px;
  margin-left: 12px;
  margin-top: 29px;
  margin-bottom: 44px;
}

.tel-pointer {
  cursor: pointer;
}

.col-paddings-r-l {
  padding-right: 0px;
  padding-left: 0px;
}

.col-paddings-t-b {
  padding-top: 0px;
  padding-bottom: 0px;
}

.col-content {
  display: flex;
  align-items: center;
}

.col-description {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
  margin-bottom: 0px;
  word-wrap: break-word;
  resize: none;
  height: 30px;
  width: 100%;
}

.red-dot {
  color: #e41313;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.col-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  word-wrap: break-word;
}

.information-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.content-container {
  background: var(--white, #fff);
  margin-top: 26px;
}

::v-deep.v-tabs .v-tabs-bar .v-tabs-slider {
  background-color: #00b800 !important;
  /* Укажите желаемый цвет для индикатора */
}

::v-deep .v-tab:before {
  background-color: gray;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

::v-deep .theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-icon,
.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-btn,
.theme--light.v-tabs>.v-tabs-bar .v-tab--disabled {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

::v-deep .v-tab {
  caret-color: #ff0000 !important;
  color: #333;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 0 1 auto;
  font-size: 1.1rem;
  font-weight: 600;
  font-style: normal;
  justify-content: center;
  letter-spacing: normal;
  line-height: normal;
  min-width: 90px;
  max-width: 360px;
  outline: none;
  padding: 0 16px;
  position: relative;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: none;
  transition: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-family: "Inter";
}

.breadcrums-click {
  cursor: pointer;
}

.breadcrums-title {
  color: var(--02, #848484);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  text-decoration: none;
}

.breadcrums-container {
  display: flex;
  align-items: center;
  margin-top: 7px;
}

::v-deep .v-input__slot {
  border-style: none;
  margin-bottom: 0px;
}

.title-container {
  padding-left: 40px;
  padding-right: 30px;
  padding-top: 17px;
}

.title-container-tabs {
  padding-left: 40px;
  padding-right: 30px;
  padding-top: 0px;
}

.img-pluss-btn {
  margin-left: 10px;
}

.arrow-btn {
  /* border-right: 1px solid var(--04, #eaeaea); */
  width: 56px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* .top-panel .container {
      width: 100%;
      padding: 0px !important;
      margin-right: 0 !important;
      margin-left: 0 !important;
    } */
.vertical-line {
  width: 1px;
  height: 42px;
  background: #e0e0e0;
  margin-left: 31px;
}

.vertical-line-1 {
  width: 1px;
  height: 50px;
  background: #e0e0e0;
}

.add-btn .add-text {
  color: var(--01, #333);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 12px;
  transition: color 0.3s ease;
}

.add-btn:hover .add-text {
  /* Цвет текста при наведении */
  color: black;
  /* Замените "red" на желаемый цвет текста */
  font-weight: 500;
  font-size: 17px;
}

.add-btn {
  display: flex;
  align-items: center;
  padding: 12px 0px;
  margin-left: 37px;
  cursor: pointer;
}

.top-panel {
  border-bottom: 1px solid var(--04, #eaeaea);
  background: var(--white, #fff);
  height: 50px;
  display: flex;
  align-items: center;
}

.col-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  word-wrap: break-word;
}

.information-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

@media (min-width: 1264px) {
  .container {
    max-width: 100% !important;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: 100% !important;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
export default {
  name: "Profile",
  directives: { mask },
  // props: {
  //   requestData: {},
  //   responseData: {},
  // },

  created() {
    this.userFullName = this.user.userInf.fullName;
    this.userLogin = this.user.userInf.name;
  },

  beforeDestroy() {
    window.removeEventListener("click", this.handleClickOutside);
  },

  data() {
    return {
      isActive: false,
      product: {},
      isHovered: false,
      loading: false,
      showInputMenu: false,
      selectedOption: "",
      selectedImage: null,
      uploadedImageLogo: "",
      isPlaceholderHidden: false,
      isuserFullNameFocused: false,
      isuserLoginFocused: false,
      isuserPasswordFocused: false,
      isnewPasswordFocused: false,
      isnewPasswordAgainFocused: false,
      userFullName: "",
      userLogin: "",
      userPassword: "",
      newPassword: "",
      newPasswordAgain: "",
      showPassword: { 0: false, 1: false, 2: false },
      success: false,
      isSubmitted: false,
    };
  },

  methods: {
    changeUserPassword() {
      const requestBody = {
        curentPassword: this.userPassword,
        newPassword: this.newPassword,
        newPasswordRepeat: this.newPasswordAgain,
      };
      this.isSubmitted = true;
      this.$http
        .put(`/api/Auth/ChangeCurrentPassword`, requestBody)
        .then((response) => {
          console.log("Пароль успешно изменен" + response);
          this.userPassword = ""; // Сброс текущего пароля
          this.newPassword = ""; // Сброс нового пароля
          this.newPasswordAgain = "";
          this.success = true; // Сброс повторного ввода нового пароля
        })
        .catch((error) => {
          this.success = false;
          console.log(error);
        });
    },
    getRestaurant() {
      // this.$http
      //   .get(`/api/Product/${this.$route.params.productId}`)
      //   .then((response) => {
      //     this.product = response.data;
      //     this.productName = response.data.name;
      //     this.description = response.data.description;
      //     this.selectedOption = response.data.category.name;
      //     this.price = response.data.price + "₸";
      //     this.loading = true;
      //     if (response.data.images.length && response.data.images[2]) {
      //       this.uploadedImageLogo =
      //         process.env.VUE_APP_API_HOST + response.data.images[2].imagePath;
      //     }
      //     console.log(this.uploadedImageLogo);
      //   })
      //   .catch((error) => {
      //     alert(error);
      //   });
    },
    togglePasswordChangeBlock() {
      this.isActive = !this.isActive; // Инвертируем значение флага
      this.newPasswordAgain = "";
      this.newPassword = "";
    },
    togglePasswordVisibility(index) {
      this.showPassword[index] = !this.showPassword[index];
    },
    clearCurrencySymbolprice(event) {
      if (event.key === "Backspace" && this.price.length > 0) {
        this.price = this.price.slice(0, -1); // Удаляем последний символ
      }
    },

    adjustTextareaHeight(refName) {
      const textarea = this.$refs[refName];
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },

    hidePlaceholder(inputName) {
      if (inputName === "productName") {
        this.isproductNameFocused = true;
      } else if (inputName === "actualAdress") {
        this.isActualAdressFocused = true;
      }
    },
    restorePlaceholder() {
      this.isPlaceholderHidden = false;
      this.activeTextarea = null;
    },
    addBorderClass() {
      this.isHovered = true;
    },
    removeBorderClass() {
      this.isHovered = false;
    },
    handleClickOutside(event) {
      const inputButton = this.$refs.inputButton;
      const inputMenu = this.$refs.inputMenu;

      if (
        inputButton &&
        inputMenu &&
        !inputButton.contains(event.target) &&
        !inputMenu.contains(event.target)
      ) {
        this.showInputMenu = false;
      }
    },
    toggleInputMenu() {
      this.isHovered = false;
      this.showInputMenu = !this.showInputMenu;
    },

    selectOption() {
      // this.selectedOption = option;
      this.selectedOption = this.category.name;
      this.toggleInputMenu(); // Вызовите метод для скрытия меню (если это требуется)
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    textareaPlaceholder() {
      return this.isPlaceholderHidden ? "" : "---";
    },
  },
  mounted() {
    // this.initialize();
    //   this.getManufacturers();

    window.addEventListener("click", this.handleClickOutside);
  },

  watch: {},
};
</script>
